import { Col } from 'view-design';
<template lang="pug">
Card.results.app-info-card(dis-hover)
  h3(slot="title")
    Avatar(
      v-if="actPointlogData.game"
      :src="getGameThumb(actPointlogData.game, userInfo)"
      shape="square"
      size='large'
      style="margin-right: 8px;"
    )
    span {{ actPointlogData._id }}
  Row.info-card-body
    Col.card-cell(span="8")
      Row.card-row
        Col.card-col(span="8")
          span ID
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.actPointLogId }}
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Provider name') }}
        Col.card-row-align-right.card-col(span="16")
          router-link(:to="`/provider/${actPointlogData.provider.id}/game`") {{ actPointlogData.provider.fullname }}
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Provider id') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.provider.id }}
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Created at') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ timeFormat(actPointlogData.createdAt) }}

    Col.card-cell(span="8")
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Transaction key') }}
        Col.card-row-align-right.card-col(span="16")
          router-link(v-if="actPointlogData.type === 'Free'" :to="toPath('/betrecord', actPointlogData)") {{ actPointlogData.tradKey }}
          span(v-else-if="actPointlogData.tradKey.includes('-')") {{ actPointlogData.tradKey }}
          span(v-else) {{ actPointlogData.tradKey }}

      Row.card-row
        Col.card-col(span="8")
          span {{ $t('User id') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.userId }}
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Username') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.username }}
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('User name') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.user.fullname }}

    Col.card-cell(span="8")
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Transcation amount') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ currencyFormat(actPointlogData.actPoint, userInfo) }}
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Transaction old actPoint') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.actPointOld }}
      Row.card-row
        Col.card-col(span="8")
          span {{ $t('Transaction new actPoint') }}
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.actPointNew }}

      Row.card-row
        Col.card-col(span="8")
          span {{ actPointlogData.campaignRank ? $t('Type Campaign') : '-' }}
        Col.card-row-align-right.card-col(span="16")
          span {{ actPointlogData.campaignRank ? actPointlogData.campaignRank.name : '-'}}

</template>
<script>
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'
import moment from 'moment'

export default {
  name: 'act-point-logs-info',
  data () {
    return {
    }
  },
  mixins: [Currency, Thumbnail, Time],
  props: {
    actPointlogData: Object,
    userInfo: Object
  },
  watch: {
  },
  computed: {
  },
  methods: {
    toPath (path, { tradKey, createdAt }) {
      const date = moment(createdAt).format('YYYY-MM-DD')
      return `${path}?search=${tradKey}&date=${date}`
    }
  },
  mounted () {
  }
}
</script>
<style lang="sass" scoped>
</style>
