<template lang="pug">
AppTable.app-table(:columns="columns", :data="listData", border)
  template(slot-scope="{ row, index }", slot="createdAt")
    span {{ timeFormat(row.createdAt)  }}
  template(slot-scope="{ row, index }", slot="operator")
    span {{ row.operator.fullname }}
  template(slot-scope="{ row, index }", slot="username")
    span.pointer(
      @click="searchUser(row.username)"
    ) {{ row.username }}
  template(slot-scope="{ row, index }", slot="user")
    span.pointer(
      @click="searchUser(row.user.fullname)"
    ) {{ row.user.fullname }}
  template(slot-scope="{ row, index }", slot="actPoint")
    span(:style="balanceColor(row)") {{ row.actPoint }}
  template(slot-scope="{ row, index }", slot="actPointOld")
    span {{ row.actPointOld }}
  template(slot-scope="{ row, index }", slot="actPointNew")
    span {{ row.actPointNew }}
  template(slot-scope="{ row, index }", slot="type")
    span(:style="typeColor(row)") {{ $t(`Type ${row.type}`) }}
  template(slot-scope="{ row, index }", slot="action")
    span(:style="actionColor(row)") {{ $t(`ActPoint: Action ${row.action}`) }}
</template>
<script>
import ActPointLogsInfo from '~v/act-point-logs/act-point-logs-info'
import AppTable from '~c/app-table'
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'

export default {
  name: 'act-point-logs-table',
  components: {
    ActPointLogsInfo,
    AppTable
  },
  mixins: [Currency, Thumbnail, Time],
  data () {
    return {
    }
  },
  props: {
    listData: Array,
    userInfo: Object
  },
  watch: {
  },
  computed: {
    // 遊戲表單
    columns () {
      let columns = [
        {
          title: 'ID',
          key: 'actPointLogId',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Created at'),
          slot: 'createdAt',
          align: 'center',
          ellipsis: true,
          width: 150
        },
        {
          title: this.$t('Operator'),
          slot: 'operator'
        },
        {
          title: this.$t('Username'),
          slot: 'username',
          ellipsis: true
        },
        {
          title: this.$t('User name'),
          slot: 'user',
          ellipsis: true
        },
        {
          title: this.$t('Transcation actPoint\'s amount'),
          slot: 'actPoint'
        },
        {
          title: this.$t('Transaction old actPoint'),
          slot: 'actPointOld'
        },
        {
          title: this.$t('Transaction new actPoint'),
          slot: 'actPointNew'
        },
        {
          title: this.$t('Transaction type'),
          slot: 'type',
          width: 90,
          align: 'center'
        },
        {
          title: this.$t('Transaction action'),
          slot: 'action',
          align: 'center'
        },
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(ActPointLogsInfo, {
              props: {
                actPointlogData: params.row,
                userInfo: this.userInfo
              }
            })
          }
        }
      ]

      return columns
    }
  },
  methods: {
    typeColor (row) {
      const warn = ['Free', 'Card']
      return { color: warn.includes(row.type) ? '#ed4014' : '#19be6b' }
    },
    actionColor (row) {
      const warn = ['Win', 'Out', 'Refund', 'Reward']
      return { color: warn.includes(row.action) ? '#ed4014' : '#19be6b' }
    },
    balanceColor (row) {
      return { color: row.actPoint > 0 ? '#ed4014' : '#19be6b' }
    },
    // user fullname 欄位傳遞 value 到 index.vue
    searchUser (fullname) {
      this.$emit('after-click-fullname', fullname)
    }
  }
}
</script>
<style lang="sass" scoped>
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
